<template>
  <div class="container">
    <h2>Passwort ändern</h2>
    <div class="form-group row">
      <label for="inputCcsId" class="col-sm-3 col-form-label">Neues Passwort</label>
      <div class="col-sm-9 input-group">
        <input type="password" class="form-control" id="inputCcsId" v-model="data.new_password">
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-9">
        <button type="submit" class="btn btn-primary" v-on:click="saveChanges">Passwort speichern</button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ChangePassword',
  data () {
    return {
      data: {}
    }
  },
  methods: {

    saveChanges: function () {
      if (!this.data.new_password) {
        toastr.error("Das steht ja gar nix drin");
        return;
      }
      this.loadingData = true;
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/auth/password", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
        body:JSON.stringify(this.data),
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          if (response.status === 422) {
            throw new Error("Das Passwort muss mind. 6 Zeichen haben.");
          }
          if (response.status === 400) {
            throw new Error("Es ist ein technischer Fehler aufgetreten.");
          }
          return response.json();
        })
        .then((data) => {
          console.log("XXX");
          console.log(data);
          this.retrievals = data;
          this.loadingData = false;
          toastr.success('Passwort wurde erfolgreich aktualisiert.');
          this.data.new_password = ''
        })
        .catch((err) => {
          console.log(err)
          this.loadingData = false;
          toastr.error(err.message);
        });
    },

  }
}
</script>


<style scoped>
</style>
