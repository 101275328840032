<template>
<div class="container">
  <h1>Neues Produkt anlegen</h1>
    <div class="form-group row">
      <label for="inputCcsId" class="col-sm-3 col-form-label">CCS-ID</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="inputCcsId" placeholder="CCS-ID" v-model="product.ccsId">
      </div>
    </div>
    <div class="form-group row">
      <label for="inputAsinEk1" class="col-sm-3 col-form-label">ASIN EK1 EK2 VK1 VK2</label>
      <div class="col-sm-2">
        <input type="text" class="form-control" id="inputAsinEk1" placeholder="ASIN EK1" v-model="product.asinEk1">
      </div>
      <div class="col-sm-2">
        <input type="text" class="form-control" id="inputAsinEk2" placeholder="ASIN EK2" v-model="product.asinEk2">
      </div>
      <div class="col-sm-2">
        <input type="text" class="form-control" id="inputAsinVk1" placeholder="ASIN VK1" v-model="product.asinVk1">
      </div>
      <div class="col-sm-2">
        <input type="text" class="form-control" id="inputAsinVk2" placeholder="ASIN VK2" v-model="product.asinVk2">
      </div>
    </div>
    <div class="form-group row">
      <label for="inputSysteam" class="col-sm-3 col-form-label">Systeam-ID</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="inputName" placeholder="Systeam-ID" v-model="product.systeam">
      </div>
    </div>
    <div class="form-group row">
      <label for="inputConradId" class="col-sm-3 col-form-label">Conrad-ID</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="inputConradId" placeholder="Conrad-ID" v-model="product.conradId">
      </div>
    </div>
    <div class="form-group row">
    <div class="col-sm-10">
      <button type="submit" class="btn btn-primary" v-on:click="saveChanges">Änderungen speichern</button>
    </div>
  </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'NewProduct',
  data () {
    return {
      product: {}
    }
  },
  methods: {
    saveChanges: function() {
     console.log("Saving changes now:");
     console.log(this.product);
     console.log("Stringifiziert: " + JSON.stringify(this.product));
     fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/products", {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + localStorage.token
       },
       body:JSON.stringify(this.product)
     })
        .then(response => {
        if (response.status === 401) {
          delete localStorage.token;
          delete localStorage.username;
          delete localStorage.name;
          delete localStorage.email;
          this.$router.push('/login?redirect=' + this.$route.path);
        }
        return response.json();
        })
        .then((data) => {
          this.$router.push({ name: 'EditProduct', params: { id: data.id } });
          toastr.info('Die Änderungen wurden gespeichert.');
        })
        .catch((err)=> {
          console.log(err);
          toastr.error('Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es noch einmal. Falls das Problem wieder auftritt, informieren Sie bitte den Administrator.');
        });
    }
  },
}
</script>


<style scoped>

</style>
