<template>
  <div class="login-wrapper border border-light">
    <form class="form-signin" @submit.prevent="login">
      <h2 class="form-signin-heading">Login</h2>
      <label for="inputEmail" class="sr-only">Benutzername (normalerweise die eigene Email ohne @computation.de)</label>
      <input v-model="username" type="text" id="inputEmail" class="form-control" placeholder="Benutzername" required autofocus>
      <label for="inputPassword" class="sr-only">Passwort</label>
      <input v-model="password" type="password" id="inputPassword" class="form-control" placeholder="Passwort" required>
      <button class="btn btn-lg btn-primary btn-block" type="submit">Anmelden</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      user: {}
    }
  },
  methods: {
    login () {
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/auth", {
         method: 'POST',
         headers: new Headers(),
         body: JSON.stringify({'username':this.username,'password':this.password})
       })
          .then((response) => {
            if (response.status === 401) {
              throw new Error("Benutzername und/oder Passwort sind nicht korrekt, Anmeldung nicht möglich.");
            }
            return response.json();
          })
          .then((data) =>  {
            console.log(data);
            this.user = data;
            this.loginSuccessful(data.authKey, data.username, data.name, data.email);
            toastr.success("Anmeldung erfolgreich, willkommen zurück! :-)");
          })
          .catch((err) => {
            toastr.error(err.message);
            this.loginFailed()
          })
    },

    loginSuccessful (token, username, name, email) {
      if (!token) {//(!req.data.token) {
        this.loginFailed();
        return;
      }

      localStorage.token = token;
      localStorage.username = username;
      localStorage.email = email;
      localStorage.name = name;
      this.error = false;

      this.$router.replace(this.$route.query.redirect || '/');
    },

    loginFailed () {
      this.error = 'Login failed!';
      delete localStorage.token;
      delete localStorage.username;
      delete localStorage.name;
      delete localStorage.email;
    },
  }
}
</script>

<style lang="css">

.login-wrapper {
  background: #fff;
  width: 70%;
  margin: 12% auto;
}

.form-signin {
  max-width: 330px;
  padding: 10% 15px;
  margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
