<template>
  <div class="container-fluid">
    <p><router-link :to="{name: 'ChannelAmazonProductList'}">Zurück zur Amazon-Artikel-Liste</router-link></p>
    <p>
      <button class="btn btn-info" v-on:click="loadData"><span class="oi oi-reload"></span></button>
      <button class="btn btn-primary" v-on:click="checkSubmissionResults">Abrufen</button>
      <img v-show="loadingData" width="2%" src="../assets/spinner.gif">
    </p>

    <div v-show="submissionResults.length > 0">
      <div class="alert alert-success" role="alert">
        <table class="table table-sm">
          <thead class="thead-dark">
            <th>ID</th>
            <th>FeedSubmissionId</th>
            <th>MessageId</th>
            <th>Old Status</th>
            <th>New Status</th>
            <th>Message</th>
            <th>New SKU ProductStatus</th>
            <th>New SKU PriceStatus</th>
            <th>New SKU InventoryStatus</th>
            <th>Amazon API Response</th>
          </thead>
          <tbody>
            <tr v-for="row in submissionResults">
              <td><span>{{row.id}}</span></td>
              <td><span>{{row.feedSubmissionId}}</span></td>
              <td><span>{{row.messageId}}</span></td>
              <td><span>{{row.oldStatus}}</span></td>
              <td><span>{{row.newStatus}}</span></td>
              <td><span>{{row.message}}</span></td>
              <td><span>{{row.newSkuProductStatus}}</span></td>
              <td><span>{{row.newSkuPriceStatus}}</span></td>
              <td><span>{{row.newSkuInventoryStatus}}</span></td>
              <td><span>{{row.api_response}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-show="data.length > 0">
      <table class="table table-sm">
        <thead class="thead-dark">
          <th>ID</th>
          <th>SKU</th>
          <th>FeedType</th>
          <th>FeedSubmissionId</th>
          <th>MessageID</th>
          <th>Status</th>
          <th>Message</th>
          <th>Submitted</th>
          <th>Started</th>
          <th>Completed</th>
          <th>Letztes Update</th>
        </thead>
        <tbody>
          <tr v-for="row in data">
            <td><span>{{row.id}}</span></td>
            <td><span>{{row.sku}}</span></td>
            <td><span>{{row.feedType}}</span></td>
            <td><span>{{row.feedSubmissionId}}</span></td>
            <td><span>{{row.messageId}}</span></td>
            <td><span>{{row.status}}</span></td>
            <td><span>{{row.message}}</span></td>
            <td><span v-show="row.submittedDate">{{moment(row.submittedDate).format("DD.MM.YYYY HH:mm")}}</span></td>
            <td><span v-show="row.startedProcessingDate">{{moment(row.startedProcessingDate).format("DD.MM.YYYY HH:mm")}}</span></td>
            <td><span v-show="row.completedProcessingDate">{{moment(row.completedProcessingDate).format("DD.MM.YYYY HH:mm")}}</span></td>
            <td><span v-show="row.lastUpdatedDate">{{moment(row.lastUpdatedDate).format("DD.MM.YYYY HH:mm")}}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AmazonMwsSubmissions',
  props:[],
  data () {
    return {
      data: [],
      loadingData: false,
      submissionResults: [],
    }
  },
  mounted: function () {
    this.loadData();
  },

  updated: function() {
  },

  methods: {
    loadData: function() {
      this.loadingData = true;
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/amazon/mws/submission", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          return response.json();
        })
        .then((data) => {
          this.data = data;
          this.loadingData = false;
        })
        .catch((err) => {
          console.log(err)
          this.loadingData = false;
        });
    },

    checkSubmissionResults: function() {
      this.loadingData = true;
      this.submissionResults = [];
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/amazon/mws/submission/refresh", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          return response.json();
        })
        .then((data) => {
          this.submissionResults = data;
          if (this.submissionResults.length > 0) {
            this.loadData();
          } else {
            this.loadingData = false;
          }
        })
        .catch((err) => {
          console.log(err)
          this.loadingData = false;
        });
    },
  },
}
</script>


<style scoped>

</style>
