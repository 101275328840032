import Vue from 'vue'
import App from './App.vue'
import ProductList from './components/ProductList.vue'
import ProductSearch from './components/ProductSearch.vue'
import NewProduct from './components/NewProduct.vue'
import EditProduct from './components/EditProduct.vue'
import ListRetrievals from './components/ListRetrievals.vue'
import ApiCallLog from './components/ApiCallLog.vue'
import Login from './components/Login.vue'
import ChangePassword from './components/ChangePassword.vue'
import Stats from './components/Stats.vue'
import About from './components/About.vue'
import AllActivities from './components/AllActivities.vue'
import ConfigurationValues from './components/ConfigurationValues.vue'
import SkuExcelUpload from './components/SkuExcelUpload.vue'
import SkuExcelCcsUpload from './components/SkuExcelCcsUpload.vue'
import Sku from './components/Sku.vue'
import AmazonMwsSubmissions from './components/AmazonMwsSubmissions.vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'

import moment from 'moment'
Vue.use(require('vue-moment'));

Vue.use(VueResource)
Vue.use(VueRouter)

Vue.prototype.moment = moment
moment.locale('de');

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', component: ProductSearch },
    { path: '/products/new', component: NewProduct, name: 'NewProduct' },
    { path: '/products/:id', component: EditProduct, name: 'EditProduct', props: true },
    { path: '/retrievals', component: ListRetrievals, name: 'ListRetrievals' },
    { path: '/api-call-logs', component: ApiCallLog, name: 'ApiCallLog' },
    { path: '/login', component: Login, name: 'Login' },
    { path: '/profile/password', component: ChangePassword, name: 'ChangePassword' },
    { path: '/about', component: About, name: 'About' },
    { path: '/activities', component: AllActivities, name: 'AllActivities' },
    { path: '/stats', component: Stats, name: 'Stats' },
    { path: '/system/configuration', component: ConfigurationValues, name: 'ConfigurationValues' },
    { path: '/channels/amazon/upload/oldexcel', component: SkuExcelUpload, name: 'SkuExcelUploadOld' },
    { path: '/channels/amazon/upload/excel', component: SkuExcelCcsUpload, name: 'ChannelAmazonExcelUpload' },
    { path: '/channels/amazon', component: Sku, name: 'ChannelAmazonProductList' },
    { path: '/channels/amazon/submissions', component: AmazonMwsSubmissions, name: 'ChannelAmazonSubmissionList' },
  ]
})

// Redirect to the home route if any routes are unmatched
//router.redirect({
//  '*': '/home'
//})

// Start the app on the #app div
//router.start(App, '#app')


new Vue({
  router,
  created: function() {
    if (!localStorage.token && this.$route.path !== '/login') {
      this.$router.push('/login?redirect=' + this.$route.path);
    }
  },
  updated: function () {
    if (!localStorage.token && this.$route.path !== '/login') {
      this.$router.push('/login?redirect=' + this.$route.path);
    }
  },
  methods: {
    isLoggedIn: function() {
      if (localStorage.token) {
        return true;
      }
      return false;
    },
    getLoggedInUsername: function() {
      return localStorage.username;
    },
    getLoggedInUserFullname: function() {
      return localStorage.name;
    },
    getLoggedInUserEmail: function() {
      return localStorage.useremail;
    },
    isUserBetaUser() {
      return "fischer" === this.getLoggedInUsername() || "hendrik.beck" === this.getLoggedInUsername();
    },
    logout: function() {
      delete localStorage.token;
      delete localStorage.username;
      delete localStorage.name;
      delete localStorage.email;
      this.$router.push('/login?redirect=' + this.$route.path);
    },
  },
  template: `
    <div>
    <nav class="navbar navbar-expand-lg navbar-dark sticky-top" style="background-color: #900; margin-bottom: 20px;" v-if="this.isLoggedIn()">
      <a class="navbar-brand" href="#">Computation X</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <router-link to="/" class="nav-item nav-link active">Home</router-link>
          <router-link to="/channels/amazon" class="nav-item nav-link active" v-if="isUserBetaUser()">Amazon</router-link>
          <router-link to="/activities" class="nav-item nav-link active">Aktivitäten</router-link>
          <router-link to="/retrievals" class="nav-item nav-link active">Retrievals</router-link>
          <router-link to="/api-call-logs" class="nav-item nav-link active">API Call-Log</router-link>
          <router-link to="/stats" class="nav-item nav-link active">Stats</router-link>
          <router-link to="/system/configuration" class="nav-item nav-link active">Configuration</router-link>
          <router-link to="/about" class="nav-item nav-link active">About</router-link>
        </div>
      </div>
      <form class="form-inline my-2 my-lg-0">
        <router-link to="/products/new" class="btn btn-primary my-2 my-sm-0">Artikel hinzufügen</router-link>
      </form>
      <div class="">
        <div class="navbar-nav">
          <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{this.getLoggedInUsername()}}
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <router-link to="/profile/password" class="dropdown-item">Password ändern</router-link>
            <a class="dropdown-item" href="#" v-on:click="logout">Logout</a>
          </div>
        </div>
      </div>
    </nav>
    <router-view></router-view>
    </div>
  `
}).$mount('#app')
