<template>

</template>
<script>
console.log("App.vue");
import ProductList from './components/ProductList.vue'
import EditProduct from './components/EditProduct.vue'

export default {
  name: 'app',
  components: {
    ProductList,
    EditProduct
  },
  data () {
    return {
      source: ""
    }
  },
  updated () {
    console.log("Checking if logged in...");
    if (!localStorage.token && this.$route.path !== '/login') {
      this.$router.push('/?redirect=' + this.$route.path);
    }
  },
  mounted () {
    console.log("App mounted...")
  },
  methods: {
    sourceChanged: function (source) {
      this.source = source;
    }
  }
}
</script>
<style>
  #app {
    padding-top: 20px
  }
</style>
