<template>
<div class="container-fluid">
  <table class="table table-hover">
    <thead class="thead-dark">
      <tr>
        <th>CCS</th>
        <th>Name</th>
        <th class="text-right">Schwellwert</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="product in products">
        <td>{{product.ccsId}}</td>
        <td>{{product.ccsName}}</td>
        <td class="text-right">{{product.schwellwert}}</td>
        <td class="text-right"><router-link :to="{ name: 'EditProduct', params: { id: product.id } }" class="btn btn-primary">Öffnen</router-link></td>
      </tr>
    </tbody>
  </table>
</div>
</template>


<script>
export default {
  name: 'ProductList',
  data () {
    return {
      products: []
    }
  },
  mounted: function () {
    console.log("Mounted");
    fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/products", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.token
      },
    })
      .then(response => {
       if (response.status === 401) {
         delete localStorage.token;
         delete localStorage.username;
         delete localStorage.name;
         delete localStorage.email;
         this.$router.push('/login?redirect=' + this.$route.path);
       }
       return response.json();
      })
      .then((data) => {
        this.products = data;
      });
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
