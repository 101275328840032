<template>
  <div class="container">
    <h2>About</h2>
    <p>Version: 1.0.2</p>
  </div>
</template>


<script>
export default {
  name: 'About',
  data () {
    return {
    }
  }
}
</script>


<style scoped>
</style>
