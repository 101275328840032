<template>
  <div class="container-fluid">
    <p><router-link :to="{name: 'ChannelAmazonExcelUpload'}">Shoutbox</router-link> | <router-link :to="{name: 'SkuExcelUploadOld'}" style="text-decoration: line-through;">Alte Shoutbox</router-link> | <router-link :to="{name: 'ChannelAmazonSubmissionList'}">Submissions</router-link> | <a href="#" data-toggle="modal" data-target="#exampleModal"><span class="oi oi-document"></span> Zustandsmodell anzeigen</a></p>
    <div>
      <button class="btn btn-info" v-on:click="loadSku" :disabled="buttonsDisabled"><span class="oi oi-reload"></span></button>
      <button class="btn btn-primary" v-on:click="postProductData" :disabled="buttonsDisabled"><span class="oi oi-data-transfer-upload"></span> Produkt senden</button>
      <button class="btn btn-primary" v-on:click="postPriceData" :disabled="buttonsDisabled"><span class="oi oi-data-transfer-upload"></span> Preis senden</button>
      <button class="btn btn-primary" v-on:click="postInventoryData" :disabled="buttonsDisabled"><span class="oi oi-data-transfer-upload"></span> Inventory senden</button>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="show101010" v-model="show101010">
        <label class="form-check-label" for="show101010">10-10-10 einblenden</label>
      </div>
      <img v-show="loadingData" width="2%" src="../assets/spinner.gif">
    </div>
    <br/>
    <div v-show="data.length > 0">
      <table class="table table-sm">
        <thead class="thead-dark">
          <th>SKU</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Product ID</th>
          <th>Product ID Type</th>
          <th>Condition Type</th>
          <th>Condition Note</th>
          <th>Published</th>
          <th style="width:100px;"></th>
          <th class="text-center">Product</th>
          <th class="text-center">Price</th>
          <th class="text-center">Inventory</th>
        </thead>
        <tbody>
          <tr v-for="row in data" v-if="(row.productStatus != 10 || row.priceStatus != 10 || row.inventoryStatus != 10) || show101010">
            <td>{{row.sku}}</td>
            <td>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
                <input size="10" class="text-right form-control" v-model="row.price" v-on:input="change(row.sku)"/>
              </div>
            </td>
            <td><input size="5" class="text-right form-control" :value="row.quantity"  v-on:input="change(row.sku)"/></td>
            <td><input class="form-control" :value="row.productId"  v-on:input="change(row.sku)"/></td>
            <td>
              <select class="custom-select" v-model="row.productIdType" v-on:input="change(row.sku)">
                <option selected>Choose...</option>
                <option value="ASIN">ASIN</option>
                <option value="ISBN">ISBN</option>
                <option value="UPC">UPC</option>
                <option value="EAN">EAN</option>
              </select>
            </td>

            <td>
              <select class="custom-select" v-model="row.conditionType" v-on:input="change(row.sku)">
                <option selected>Choose...</option>
                <option value="New">New</option>
                <option value="UsedLikeNew">UsedLikeNew</option>
                <option value="UsedVeryGood">UsedVeryGood</option>
                <option value="UsedGood">UsedGood</option>
                <option value="UsedAcceptable">UsedAcceptable</option>
                <option value="CollectibleLikeNew">CollectibleLikeNew</option>
                <option value="CollectibleVeryGood">CollectibleVeryGood</option>
                <option value="CollectibleGood">CollectibleGood</option>
                <option value="CollectibleAcceptable">CollectibleAcceptable</option>
                <option value="Refurbished">Refurbished</option>
                <option value="Club">Club</option>
              </select>
            </td>


            <td><textarea style="font-size:75%;" rows="3" cols="80" class="form-control form-control-sm" v-on:input="change(row.sku)" v-model="row.conditionNote"></textarea></td>

            <td class="text-center"><input type="checkbox" v-model="row.published"  v-on:input="change(row.sku)"/></td>

            <td>
              <button class="btn btn-primary" v-bind:class="{'btn-secondary':row.unsavedChanges != true}" :disabled="row.unsavedChanges != true" v-on:click="patchRow(row)">Save</button>
              <img v-show="row.patchingInProgress" width="25px;" src="../assets/spinner.gif">
            </td>

            <td class="text-center" v-bind:class="{ 'table-success':shouldPostProductData(row) }">
              <span class="badge" data-toggle="tooltip" data-placement="bottom" :title="getDescriptionForState(row.productStatus)" v-bind:class="{ 'badge-danger':isErrorState(row.productStatus), 'badge-success': row.productStatus == 10, 'badge-warning': row.productStatus < 10}">
                {{getBadgeCaptionForState(row.productStatus)}}<br/><span style="font-size:16px">{{row.productStatus}}</span>
              </span>
            </td>

            <td class="text-center" v-bind:class="{ 'table-success':shouldPostPriceData(row) }">
              <span class="badge" data-toggle="tooltip" data-placement="bottom" :title="getDescriptionForState(row.priceStatus)" v-bind:class="{ 'badge-danger':isErrorState(row.productStatus), 'badge-success': row.priceStatus == 10, 'badge-warning': row.priceStatus < 10}">
                {{getBadgeCaptionForState(row.priceStatus)}}<br/><span style="font-size:16px">{{row.priceStatus}}</span>
              </span>
            </td>

            <td class="text-center" v-bind:class="{ 'table-success':shouldPostInventoryData(row) }">
              <span class="badge" data-toggle="tooltip" data-placement="bottom" :title="getDescriptionForState(row.inventoryStatus)" v-bind:class="{ 'badge-danger':isErrorState(row.inventoryStatus), 'badge-success': row.inventoryStatus == 10, 'badge-warning': row.inventoryStatus < 10}">
                {{getBadgeCaptionForState(row.inventoryStatus)}}<br/><span style="font-size:16px">{{row.inventoryStatus}}</span>
              </span>
            </td>

          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Das Zustandsmodell</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img width="100%" src="../assets/states.jpg">
            <br/>
            <br/>
            <p>Anmerkungen:</p>
            <ul>
              <li>Product, Price und Inventory durchlaufen dieses Statusmodell, jeweils getrennt voneinander</li>
              <li>Price und Inventory dürfen den Status 0 erst verlassen, wenn Product mind. den Status 10 erreicht hat (d.h. mind. einmal live war und damit bei Amazon als Lagerbestand angelegt wurde)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Sku',
  props:[],
  data () {
    return {
      data: [],
      loadingData: false,
      buttonsDisabled: false,
      show101010: false,
    }
  },
  mounted: function () {
    this.loadSku();
  },

  updated: function() {
    $('[data-toggle="tooltip"]').tooltip();
  },

  methods: {
    loadSku: function() {
      this.loadingData = true;
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/sku?count=1000", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          return response.json();
        })
        .then((data) => {
          this.data = data;
          this.loadingData = false;
          this.buttonsDisabled = false;
        })
        .catch((err) => {
          console.log(err)
          this.loadingData = false;
          this.buttonsDisabled = false;
        });
    },

    postData: function(action, skusToPost) {
      var payload = {action: action, skus: skusToPost};

      this.buttonsDisabled = true;
      this.loadingData = true;

      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/amazon/mws/submission", {
        method: 'POST',
        body:JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          return response.json();
        })
        .then((data) => {
          toastr.success('<table padding="15px"><tr><td>Submitted:</td><td style:"text-align:left">'+data.itemsSubmitted+'</td></tr><tr><td>Skipped:</td><td>'+data.itemsSkipped+'</td></tr><tr><td>Nicht gefunden:</td><td>'+data.itemsNotFound+'</td></tr><tr><td>Submission-ID:</td><td>'+data.submissionId+'</td></tr></table>', 'Ergebnisse', {timeOut: 30000, closeButton: true});
          this.loadSku();
        })
        .catch((err) => {
          toastr.error('Fehler:' + err);
          console.log(err)
          this.loadingData = false;
        });
    },

    postProductData: function() {
      var skusToPost = this.getArrayOfSkusToPostProductData();
      if (skusToPost.length == 0) {
        toastr.warning("Keine Einlistungen notwendig");
        return;
      }
      this.postData("product", skusToPost);
    },

    postPriceData: function() {
      var skusToPost = this.getArrayOfSkusToPostPriceData();
      if (skusToPost.length == 0) {
        toastr.warning("Keine Einlistungen notwendig");
        return;
      }
      this.postData("price", skusToPost);
    },

    postInventoryData: function() {
      var skusToPost = this.getArrayOfSkusToPostInventoryData();
      if (skusToPost.length == 0) {
        toastr.warning("Keine Einlistungen notwendig");
        return;
      }
      this.postData("inventory", skusToPost);
    },

    getArrayOfSkusToPostProductData: function() {
      var skusToPost = [];
      for (var x=0; x<this.data.length; x++) {
        var row = this.data[x];
        if (this.shouldPostProductData(row)) {
          skusToPost.push(row.sku);
        }
      }
      return skusToPost;
    },

    getArrayOfSkusToPostPriceData: function() {
      var skusToPost = [];
      for (var x=0; x<this.data.length; x++) {
        var row = this.data[x];
        if (this.shouldPostPriceData(row)) {
          skusToPost.push(row.sku);
        }
      }
      return skusToPost;
    },

    getArrayOfSkusToPostInventoryData: function() {
      var skusToPost = [];
      for (var x=0; x<this.data.length; x++) {
        var row = this.data[x];
        if (this.shouldPostInventoryData(row)) {
          skusToPost.push(row.sku);
        }
      }
      return skusToPost;
    },

    shouldPostProductData: function(row) {
      return row.published == true && (row.productStatus == 0 || row.productStatus == 2 || row.productStatus == 12);
    },
    shouldPostPriceData: function(row) {
      return row.published == true && row.productStatus >= 10 && (row.priceStatus == 0 || row.priceStatus == 2 || row.priceStatus == 12);
    },
    shouldPostInventoryData: function(row) {
      return row.published == true && row.productStatus >= 10 && (row.inventoryStatus == 0 || row.inventoryStatus == 2 || row.inventoryStatus == 12);
    },
    isErrorState: function(state) {
      return state == 3 || state == 13;
    },

    getDescriptionForState: function(state) {
      switch (state) {
        case 0: return "0 = Neu, noch nie hochgeladen";
        case 1: return "1 = Neu, Erst-Upload läuft";
        case 2: return "2 = Neu, Änderung wartet auf Erstupload";
        case 3: return "3 = Neu, Erst-Upload fehlgeschlagen";
        case 10: return "10 = Information ist live";
        case 11: return "11 = Information ist live, Aktualisierung wird gerade hochgeladen";
        case 12: return "12 = Information ist live, Änderung muss noch hochgeladen werden";
        case 13: return "13 = Information ist live, Upload von Änderung ist fehlgeschlagen";
      }
    },

    getBadgeCaptionForState: function(state) {
      switch (state) {
        case 0: return "Neu";
        case 1: return "In Progress";
        case 2: return "Änderung";
        case 3: return "Fehler";
        case 10: return "LIVE";
        case 11: return "In Progress";
        case 12: return "Änderung";
        case 13: return "Fehler";
      }
    },

    change: function(skuId) {
      for (var x=0; x<this.data.length; x++) {
        var row = this.data[x];
        if (row.sku === skuId) {
          this.$set(row, 'unsavedChanges', true);
          return;
        }
      }
      console.log("Didn't find " + skuId + ". Can't change.");
    },
    patchRow: function(row) {
      var sku = this.getSku(row.sku);
      this.$set(row, 'patchingInProgress', true);
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/sku", {
        method: 'POST',
        body: JSON.stringify([row]),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          return response.json();
        })
        .then((data) => {
          this.merge(data.sku);
          this.$set(row, 'patchingInProgress', false);
        })
        .catch((err) => {
          console.log(err)
          this.$set(row, 'patchingInProgress', false);
        });
    },

    merge: function(newSku) {
      for (var x=0; x<this.data.length; x++) {
        var row = this.data[x];
        if (row.sku === newSku.sku) {
          this.$set(row, 'price', newSku.price);
          this.$set(row, 'quantity', newSku.quantity);
          this.$set(row, 'productId', newSku.productId);
          this.$set(row, 'productIdType', newSku.productIdType);
          this.$set(row, 'conditionNote', newSku.conditionNote);
          this.$set(row, 'conditionType', newSku.conditionType);
          this.$set(row, 'published', newSku.published);
          this.$set(row, 'productStatus', newSku.productStatus);
          this.$set(row, 'priceStatus', newSku.priceStatus);
          this.$set(row, 'inventoryStatus', newSku.inventoryStatus);
          this.$set(row, 'unsavedChanges', false);
          return;
        }
      }
      console.log("Couldn't find sku '"+newSku.sku+"', did not merge back response from PATCH call.");
    },

    getSku: function(skuId) {
      for (var x=0; x<this.data.length; x++) {
        var row = this.data[x];
        if (row.sku === skuId) {
          return row;
        }
      }
    },

  },
}
</script>


<style scoped>

</style>
