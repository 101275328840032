<template>
  <div class="container-fluid">
    <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th>Timestamp</th>
          <th>ID-Type</th>
          <th>ID</th>
          <th>ShopName</th>
          <th class="text-right">Preis</th>
          <th>PreisTyp</th>
          <th>Währung</th>
          <th>MWSt.</th>
          <th>Währungsumrechnung</th>
          <th>Lagerstand</th>
          <th>Quelle</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="r in retrievals">
          <td>{{moment(r.timestamp).format("DD.MM.YYYY HH:mm")}}</td>
          <td>{{r.idType}}</td>
          <td>{{r.id}}</td>
          <td>{{r.shopName}}</td>
          <td>{{r.price}}</td>
          <td>{{r.priceType}}</td>
          <td>{{r.currency}}</td>
          <td>{{r.vatUsedForConversion}}</td>
          <td>{{r.exchangeRateUsedForConversion}}</td>
          <td>{{r.inventoryAvailable}}</td>
          <td>{{r.source}}</td>
        </tr>
      </tbody>
    </table>
    <br/>
    <img v-show="loadingData" width="3%" src="../assets/spinner.gif">
  </div>
</template>


<script>
export default {
  name: 'ListRetrievals',
  props:['id'],
  data () {
    return {
      retrievals: {},
      loadingData: false,
    }
  },
  mounted: function () {
    this.loadingData = true;
    fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/retrievals", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.token
      },
    })
      .then(response => {
       if (response.status === 401) {
         delete localStorage.token;
         delete localStorage.username;
         delete localStorage.name;
         delete localStorage.email;
         this.$router.push('/login?redirect=' + this.$route.path);
       }
       return response.json();
      })
      .then((data) => {
        this.retrievals = data;
        this.loadingData = false;
      })
      .catch((err) => {
        console.log(err)
        this.loadingData = false;
      });
  },
}
</script>


<style scoped>
</style>
