<template>
  <div class="container-fluid">
    <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th>Timestamp</th>
          <th>API</th>
          <th>Auth-Key</th>
          <th class="text-right">HTTP Status Code</th>
          <th class="text-right">Neue Produkte</th>
          <th class="text-right">Aktualisierte Produkte</th>
          <th class="text-right">Neue Retrievals</th>
          <th class="text-right">Fehler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="r in retrievals" v-bind:class="{ 'table-danger': r.httpStatusCode>=300}">
          <td>{{moment(r.timestamp).format("DD.MM.YYYY HH:mm")}}</td>
          <td>{{r.api}}</td>
          <td>{{r.authKey}}</td>
          <td class="text-right">{{r.httpStatusCode}}</td>
          <td class="text-right">{{r.productsCreated}}</td>
          <td class="text-right">{{r.productsUpdated}}</td>
          <td class="text-right">{{r.retrievalsCreated}}</td>
          <td class="text-right">{{r.numberOfErrors}}</td>
        </tr>
      </tbody>
    </table>
    <br/>
    <img v-show="loadingData" width="3%" src="../assets/spinner.gif">
  </div>
</template>


<script>
export default {
  name: 'ApiCallLog',
  props:['id'],
  data () {
    return {
      retrievals: {},
      loadingData: false,
    }
  },
  mounted: function () {
    this.loadingData = true;
    fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/system/api-call-logs", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.token
      },
    })
      .then(response => {
        if (response.status === 401) {
          delete localStorage.token;
          delete localStorage.username;
          delete localStorage.name;
          delete localStorage.email;
          this.$router.push('/login?redirect=' + this.$route.path);
        }
        return response.json();
      })
      .then((data) => {
        this.retrievals = data;
        this.loadingData = false;
      })
      .catch((err) => {
        console.log(err)
        this.loadingData = false;
      });
  },
}
</script>


<style scoped>
</style>
