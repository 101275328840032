<template>
  <div class="container">
    <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th>Key</th>
          <th>Value</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="value in data">
          <td>{{value.key}}</td>
          <td>{{value.value}}</td>
          <td>{{value.description}}</td>
        </tr>
      </tbody>
    </table>
    <br/>
    <img v-show="loadingData" width="3%" src="../assets/spinner.gif">
  </div>
</template>


<script>
export default {
  name: 'ConfigurationValues',
  data () {
    return {
      data: {},
      loadingData: false,
    }
  },
  mounted: function () {
    this.loadingData = true;
    fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/system/configuration/values", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.token
      },
    })
      .then(response => {
        if (response.status === 401) {
          delete localStorage.token;
          delete localStorage.username;
          delete localStorage.name;
          delete localStorage.email;
          this.$router.push('/login?redirect=' + this.$route.path);
        }
        return response.json();
      })
      .then((data) => {
        this.data = data;
        this.loadingData = false;
      })
      .catch((err) => {
        console.log(err)
        this.loadingData = false;
      });
  },
}
</script>


<style scoped>
</style>
