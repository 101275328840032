<template>
  <div class="container-fluid">
    <p><router-link :to="{name: 'ChannelAmazonProductList'}">Zurück zur Amazon-Artikel-Liste</router-link></p>
    <textarea id="shoutbox" v-model="excelText">Z_1003.11188_B2	99.90	1	B008V25F36	ASIN	Refurbished	Neues und unbenutztes Ausstellungsgerät in einwandfreiem Zustand, vollständiger Lieferumfang, Ware wurde noch nicht in Betrieb genommen</textarea>
    <button class="btn btn-large btn-success" v-on:click="sendDataToParse" :disabled="excelText.length == 0">{{excelText.length > 0 ? "Text parsen" : "Bitte SKUs aus Excel hier per Copy&Paste einfügen"}}</button>
    <br/>
    <img v-show="loadingData" width="3%" src="../assets/spinner.gif">
    <br/>

    <div v-show="parsedData.length > 0">
      <table class="table table-bordered table-sm">
        <thead>
          <th>SKU</th>
          <th>articleId</th>
          <th>Condition</th>
          <th>ArticleName</th>
          <th>PM</th>
          <th>AdditionalConditionDescription</th>
          <th>Anzahl</th>
          <th>AgeMax</th>
          <th>ArticleClass</th>
          <th>Since</th>
          <th>LASTUPD</th>
          <th>ASIN</th>
          <th>SysteamID</th>
          <th>Startpreis</th>
          <th>Existiert in CCS?</th>
        </thead>
        <tbody>
          <tr v-for="row in parsedData">
            <parsed-row :item="row.fields.sku"></parsed-row>
            <parsed-row :item="row.fields.articleId"></parsed-row>
            <parsed-row :item="row.fields.condition"></parsed-row>
            <parsed-row :item="row.fields.articleName"></parsed-row>
            <parsed-row :item="row.fields.pm"></parsed-row>
            <parsed-row :item="row.fields.additionalConditionDescription"></parsed-row>
            <parsed-row :item="row.fields.anzahl"></parsed-row>
            <parsed-row :item="row.fields.ageMax"></parsed-row>
            <parsed-row :item="row.fields.articleClass"></parsed-row>
            <parsed-row :item="row.fields.since"></parsed-row>
            <parsed-row :item="row.fields.lastUpdated"></parsed-row>
            <parsed-row :item="row.fields.asin"></parsed-row>
            <parsed-row :item="row.fields.systeamId"></parsed-row>
            <parsed-row :item="row.fields.startpreis"></parsed-row>
            <td>{{row.ccsInfo.exists ? "Ja" : "Nein"}}</td>
          </tr>
        </tbody>
      </table>
      <br/>
      <div class="alert alert-danger" role="alert"v-if="containsErrors">Es sind {{numberOfErrors}} Fehler vorhanden, der Upload kann daher nicht durchgeführt werden. Bitte beseitigen Sie den Fehler, bevor Sie fortfahren.</div>
      <button class="btn btn-large btn-success" v-on:click="uploadData" :disabled="!readyToUpload">{{readyToUpload ? "Upload" : "Bitte Fehler beseitigen"}}</button>
    </div>
  </div>
</template>


<script>
var compi = {
  props: ['item'],
  template: '<td v-bind:class="{ \'field-not-valid\': item.valid == false, existsAndEqual: item.ccsInfo && item.ccsInfo.equal == true, existsAndDifferent: item.ccsInfo && item.ccsInfo.equal == false}" data-toggle="tooltip" data-placement="top" :title="item.message"><span>{{item.value}}</span></td>'
};

export default {
  name: 'SkuExcelUpload',
  props:[],
  data () {
    return {
      excelText: '',
      parsedData: [],
      loadingData: false,
      readyToUpload: false,
      containsErrors: false,
      numberOfErrors: 0,
    }
  },
  mounted: function () {
  },

  updated: function() {
    $('[data-toggle="tooltip"]').tooltip();
  },

  components: {
    'parsed-row': compi,
  },

  methods: {
    sendDataToParse: function() {
      this.loadingData = true;
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/sku/excelccs", {
        method: 'POST',
        body: this.excelText,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          return response.json();
        })
        .then((data) => {
          this.parsedData = data;
          this.loadingData = false;
          this.updateUploadReadiness();
        })
        .catch((err) => {
          console.log(err)
          this.loadingData = false;
        });
    },

    uploadData: function() {
      this.loadingData = true;
      var body = this.getUploadPayload();

      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/amazon/ccs", {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          if (response.status > 401) {
            throw new Exception();
          }
          return response.json();
        })
        .then((data) => {
          this.sendDataToParse();
          this.loadingData = false;
          toastr.success('<table padding="15px"><tr><td>Neu angelegt:</td><td style:"text-align:left">'+data.newItems+'</td></tr><tr><td>Aktualisiert:</td><td>'+data.updatedItems+'</td></tr><tr><td>Unverändert:</td><td>'+data.unchangedItems+'</td></tr><tr><td>Fehler:</td><td>'+data.errors+'</td></tr></table>', 'Ergebnisse', {timeOut: 20000, closeButton: true});
        })
        .catch((err) => {
          console.log(err)
          this.loadingData = false;
          toastr.error('Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es noch einmal. Falls das Problem wieder auftritt, informieren Sie bitte den Administrator.');
        });
    },

    updateUploadReadiness: function() {
      if (this.parsedData == null || this.parsedData.length == 0) {
        this.readyToUpload = false;
        return;
      }

      this.numberOfErrors = 0;

      for (var x=0; x<this.parsedData.length; x++) {
        this.numberOfErrors += this.parsedData[x].numberOfErrors;
      }

      this.readyToUpload = this.numberOfErrors == 0;
      this.containsErrors = this.numberOfErrors > 0;
    },

    getUploadPayload: function() {
      var result = [];
      for (var x=0; x<this.parsedData.length; x++) {
        var row = this.parsedData[x];
        var item = {};
        item.sku = row.fields.sku.value;
        item.price = Number(row.fields.startpreis.value);
        item.quantity = Number(row.fields.anzahl.value);
        item.asin = row.fields.asin.value;
        item.condition = row.fields.condition.value;
        item.additionalConditionDescription = row.fields.additionalConditionDescription.value;
        result.push(item);
      }
      console.log(JSON.stringify(result));
      return JSON.stringify(result);
    }
  },
}
</script>


<style scoped>
textarea {
  width: 100%;
  height: 20vw;
}
.field-not-valid {
  background-color:#ef8787;
}
.exists {
  text-decoration: line-through;
  background-color:#dddddd !important;
}
.existsAndEqual {
  background-color: #0bf10b;
}
.existsAndDifferent {
  background-color: red;
}
</style>
