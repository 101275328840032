<template>
<div class="container-fluid">
  <input type="text" class="form-control" style="width:100%" @input="isTyping = true" v-model="query" placeholder="Deine Suche, z.B. 'laserdrucker', 'laserdr', '1004.10157' oder '157'"></input>
  <br/>
  <div align="center" v-if="isLoading">
    <span>Searching...</span>
  </div>
  <div align="center" v-if="apiErrorMessage != null">
    <span>{{apiErrorMessage}}</span>
  </div>
  <table class="table table-hover" v-if="products && products.length > 0">
          <thead class="thead-dark">
            <tr>
              <th>CCS</th>
              <th>Name</th>
              <th class="text-right">Schwellwert</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products">
              <td>{{product.ccsId}}</td>
              <td>{{product.ccsName}}</td>
              <td class="text-right">{{product.schwellwert}}</td>
              <td class="text-right"><router-link :to="{ name: 'EditProduct', params: { id: product.id } }" class="btn btn-primary">Öffnen</router-link></td>
            </tr>
          </tbody>
        </table>
</div>
</template>


<script>
import _ from 'lodash'

export default {
  name: 'ProductList',
  data () {
    return {
      products: [],
      query: "",
      isTyping: false,
      isLoading: false,
      apiErrorMessage: null
    }
  },
  watch: {
    query: _.debounce(function() {
      this.isTyping = false;
    }, 1000),
    isTyping: function(value) {
      if (!value) {
        this.search(this.query);
      }
    }
  },
  methods: {
    search: function(query) {
      this.isLoading = true;
      this.tooManyResults = false;
      this.apiErrorMessage = null;
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/products/search?q=" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          return response.json();
        })
        .then(data => {
          if (data.code) {
            if (data.code === "SEARCH_QUERY_TOO_SHORT" && query.length > 0) {
              this.apiErrorMessage = "Deine Suchanfrage ist zu kurz. Bitte verwende mind. 3 Zeichen...";
              this.isLoading = false;
              console.log("1");
            }
            if (data.code === "TOO_MANY_RESULTS") {
              this.apiErrorMessage = "Zu viele Resultate. Bitte verfeinere Deine Suche...";
              this.isLoading = false;
              console.log("2");
            }
          }
          return data;
        })
        .then((data) => {
          this.products = data;
          this.isLoading = false;
        }).catch((err) => {
          console.log(err);
          toastr.error('Ein Fehler bei der Suche ist aufgetreten.');
        });
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
