<template>
  <div class="container">
    <h1>Stats</h1>
    <button class="btn btn-info" v-on:click="reloadAllData">Alle aktualisieren</button>
    <br/>
    <br/>
    <div>
      <h2>
        Retrievals
        <img v-show="loadStatusData === 'loading'" width="2%" src="../assets/spinner.gif">
        <span style="color: green; font-size:1rem;" v-show="loadStatusData === 'finished'">Aktualisiert</span>
      </h2>
      <div class="form-inline row">
        <div class="col-sm-12">
          <button class="btn btn-success" v-on:click="loadRetrievalStats1H">1h (1min)</button>
          <button class="btn btn-success" v-on:click="loadRetrievalStats1D">24h (10min)</button>
          <button class="btn btn-success" v-on:click="loadRetrievalStats2D">48d (1h)</button>
          <button class="btn btn-success" v-on:click="loadRetrievalStats1W">1w (1h)</button>
          <span id="labelInputFieldsDistance">Stunden zurück:</span>
          <input type="number" class="form-control" id="inputDistance" placeholder="Wie weit zurück?" v-model="paramDistance" min="0" max="2160">
          <span id="labelInputFieldsPeriods">Anzahl Abschnitte:</span>
          <input type="number" class="form-control" id="inputPeriods" placeholder="Wieviele Abschnitte?" v-model="paramPeriods" min="0" max="100">
          <button class="btn btn-info" v-on:click="loadRetrievalStats">Aktualisieren</button>
        </div>
      </div>

      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="chart-tab" data-toggle="tab" href="#chart" role="tab" aria-controls="chart" aria-selected="true">Chart</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="table-tab" data-toggle="tab" href="#table" role="tab" aria-controls="table" aria-selected="false">Werte</a>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="chart" role="tabpanel" aria-labelledby="chart-tab">
          <div id="chart"></div>
        </div>
        <div class="tab-pane fade show" id="table" role="tabpanel" aria-labelledby="table-tab">
          <table class="table">
            <thead>
              <th>Von</th>
              <th>Bis</th>
              <th>Gesamt</th>
              <th>Fehlerhaft</th>
              <th>Erfolgreich</th>
              <th>Fehlerrate</th>
            </thead>
            <tbody>
              <tr v-for="line in retrievalsStats">
                <td>{{moment(line.periodFrom).format('DD.MM.YYYY HH:mm')}}</td>
                <td>{{moment(line.periodTo).format('DD.MM.YYYY HH:mm')}}</td>
                <td>{{line.countTotal}}</td>
                <td>{{line.countErrors}}</td>
                <td>{{line.countTotal - line.countErrors}}</td>
                <td v-bind:class="{fehler: (line.countErrors/line.countTotal) > 0.1}"><span v-if="line.countTotal>0">{{(line.countErrors/line.countTotal*100).toFixed(1)}}%</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



      <h2>
        ASIN
        <img v-show="loadStatusAsin === 'loading'" width="2%" src="../assets/spinner.gif">
        <span style="color: green; font-size:1rem;" v-show="loadStatusAsin === 'finished'">Aktualisiert</span>
      </h2>
      <table class="table">
        <tbody>
          <tr>
            <td>Ältestes nicht abgerufenes Produkt:</td>
            <td>{{moment(asinStats.timestamp).format('DD.MM.YYYY HH:mm')}}</td>
          </tr>
          <tr>
            <td>ASIN-Durchlaufzeit:</td>
            <td>{{asinDurchlaufzeit.toFixed(2)}} Stunden</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Stats',
  data () {
    return {
      retrievalsStats: [],
      asinStats: {},
      asinDurchlaufzeit: -1,
      loadStatusData: 'idle',
      loadStatusAsin: 'idle',
      paramPeriods: 144,
      paramDistance: 24,
    }
  },
  created: function () {
    google.charts.load('current', {packages: ['corechart', 'line']});
  },
  mounted: function () {
    this.reloadAllData();
  },
  methods: {

    reloadAllData: function() {
      this.loadRetrievalStats();
      this.loadAsinStats();
    },

    loadRetrievalStats: function() {
      this.loadStatusData = 'loading';
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/retrievals/stats/history?periods="+this.paramPeriods+"&distance=" + this.paramDistance, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          return response.json();
        })
        .then((data) => {
          this.retrievalsStats = data;
          this.drawChart(data);
          this.loadStatusData = 'finished';
          var self = this;
          setTimeout(function() {
            console.log("Finished timeout.");
            self.loadStatusData = 'idle';
          }, 700);
        })
        .catch((err) => {
          console.log(err)
          this.loadStatusData = 'idle';
        });
    },

    loadRetrievalStats1H: function() {
      this.paramPeriods = 60;
      this.paramDistance = 1;
      this.loadRetrievalStats();
    },

    loadRetrievalStats1D: function() {
      this.paramPeriods = 144;
      this.paramDistance = 24;
      this.loadRetrievalStats();
    },

    loadRetrievalStats2D: function() {
      this.paramPeriods = 48;
      this.paramDistance = 48;
      this.loadRetrievalStats();
    },

    loadRetrievalStats1W: function() {
      this.paramPeriods = 168;
      this.paramDistance = 168;
      this.loadRetrievalStats();
    },

    loadAsinStats: function () {
      this.loadStatusAsin = 'idle';
      fetch("https://07s1fhg37a.execute-api.eu-central-1.amazonaws.com/prod/retrievals/stats/asin", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.token
        },
      })
        .then(response => {
          if (response.status === 401) {
            delete localStorage.token;
            delete localStorage.username;
            delete localStorage.name;
            delete localStorage.email;
            this.$router.push('/login?redirect=' + this.$route.path);
          }
          return response.json();
        })
        .then((data) => {
          this.asinStats = data;
          this.refreshAsinDurchlaufzeit(data.timestamp);
          this.loadStatusAsin = 'finished';
          var self = this;
          setTimeout(function() {
            console.log("Finished timeout.");
            self.loadStatusAsin = 'idle';
          }, 700);
        })
        .catch((err) => {
          console.log(err)
          this.loadStatusAsin = 'idle';
        });
    },

    refreshAsinDurchlaufzeit: function(otherDate) {
      var date = new Date();
      this.asinDurchlaufzeit = Math.abs(date - new Date(otherDate))/1000/60/60;
    },

    drawChart: function(stats) {
      var data = new google.visualization.DataTable();
      data.addColumn('datetime', 'Date');
      data.addColumn('number', 'Gesamt');
      data.addColumn('number', 'Fehler');
      data.addColumn('number', 'Erfolgreich');
      data.addColumn('number', 'Fehlerrate');

      var rows = [];
      for (var i = 0; i < stats.length; i++) {
        var line = stats[i];
        var row = [];

        row.push(new Date(line.periodTo));
        row.push(line.countTotal);
        row.push(line.countErrors);
        row.push(line.countTotal - line.countErrors);
        row.push(line.countErrors/line.countTotal*100);
        rows.push(row);
      }

      data.addRows(rows);

      var chartWidth = $('#chart').width();
      var options = {
        hAxis: {
          title: 'Datum'
        },
        vAxis: {
          title: 'Anzahl Abfragen',
        },
        axes: {
          y: {
            0: {
              label: 'Anzahl Anfragen'
            },
            1: {
              label: 'Fehlerrate',
              format:'#%',
              viewWindow:{max:100,min:0},
            }
          },
        },
        title: "Retrievals Historie",
        width: chartWidth,
        height: 400,

        series: {
            0: { targetAxisIndex: 0 },
            1: { targetAxisIndex: 0 },
            2: { targetAxisIndex: 0 },
            3: { targetAxisIndex: 1 },
        },
      };

      var chart = new google.charts.Line(document.getElementById("chart"));

      //chart.draw(data, options);
      chart.draw(data, google.charts.Line.convertOptions(options));
    },

  }
}
</script>


<style scoped>
thead tr th:first-child,
tbody tr td:first-child {
  width: 24em;
  min-width: 24em;
  max-width: 24em;
}
.fehler {
  color: red;
}
.tab-pane {
  padding-top: 30px;
}
input {
  width: 100px !important;
}
#myTab {
  margin-top: 50px;
}
#labelInputFieldsDistance {
  margin-left: 30px;
}
#labelInputFieldsPeriods {
  margin-left: 10px;
}
</style>
